<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">Determine the net spin for each of the metal atoms/ions below:</p>

      <v-simple-table style="max-width: 413px">
        <thead>
          <tr style="height: 130px">
            <td>
              <chemical-latex content="a) Zn" />
            </td>
            <td>
              <v-select
                v-model="inputs.netSpinZn"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select net spin:"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr style="height: 130px">
            <td>
              <chemical-latex content="b) Ni^2+" />
            </td>
            <td>
              <v-select
                v-model="inputs.netSpinNi"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select net spin:"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question479',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        netSpinZn: null,
        netSpinNi: null,
      },
      items: [
        {text: '+5/2', value: '+5/2'},
        {text: '+2', value: '+2'},
        {text: '+3/2', value: '+3/2'},
        {text: '+1', value: '+1'},
        {text: '+1/2', value: '+1/2'},
        {text: '0', value: '0'},
        {text: '-1/2', value: '-1/2'},
        {text: '-1', value: '-1'},
        {text: '-3/2', value: '-3/2'},
        {text: '-2', value: '-2'},
        {text: '-5/2', value: '-5/2'},
      ],
    };
  },
};
</script>
